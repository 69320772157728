<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content box" style="width: fit-content">
      <div class="title is-4">Rate this item</div>

      <div style="display: flex; justify-content: center">
        <fieldset id="ratingbox" class="rating" @click="handleClick($event)">
          <input type="radio" id="star5" name="rating" value="5" />
          <label class="full" for="star5" title="Awesome - 5 stars"></label>
          <input type="radio" id="star4half" name="rating" value="4.5" />
          <label class="half" for="star4half" title="Pretty good - 4.5 stars"></label>
          <input type="radio" id="star4" name="rating" value="4" />
          <label class="full" for="star4" title="Pretty good - 4 stars"></label>
          <input type="radio" id="star3half" name="rating" value="3.5" />
          <label class="half" for="star3half" title="Meh - 3.5 stars"></label>
          <input type="radio" id="star3" name="rating" value="3" />
          <label class="full" for="star3" title="Meh - 3 stars"></label>
          <input type="radio" id="star2half" name="rating" value="2.5" />
          <label class="half" for="star2half" title="Kinda bad - 2.5 stars"></label>
          <input type="radio" id="star2" name="rating" value="2" />
          <label class="full" for="star2" title="Kinda bad - 2 stars"></label>
          <input type="radio" id="star1half" name="rating" value="1.5" />
          <label class="half" for="star1half" title="Meh - 1.5 stars"></label>
          <input type="radio" id="star1" name="rating" value="1" />
          <label class="full" for="star1" title="Sucks big time - 1 star"></label>
          <input type="radio" id="starhalf" name="rating" value="0.5" />
          <label class="half" for="starhalf" title="Sucks big time - 0.5 stars"></label>
        </fieldset>
      </div>
      <div style="display: flex; justify-content: center; margin-top: 1rem">
        <button class="button is-small is-rounded" @click="deleteRating()">
          <font-awesome-icon icon="trash"></font-awesome-icon>
          <p style="margin-left: 5px;">Delete your rating</p>
        </button>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="$emit('closerating')"></button>
  </div>
</template>

<script>
import TMDb from "@/utils/TMDb.js";

const tmdb = new TMDb();

export default {
  name: "rating",
  props: ["ratingdata"],
  methods: {
    deleteRating() {
      this.ratingdata.type == "movie"
        ? tmdb.deleteMovieRating(this.ratingdata.id)
        : tmdb.deleteTvRating(this.ratingdata.id);
      this.$emit("closerating");
    },
    handleClick(e) {
      let rating = e.target.value;
      let id = this.ratingdata.id;
      if (rating != undefined) {
        this.ratingdata.type == "movie"
          ? tmdb.rateMovie(Number(rating) * 2, id)
          : tmdb.rateTv(Number(rating) * 2, id);
      }
    },

    fillRating(rating) {
      rating = String(rating);
      let ratingbox = document.getElementById("ratingbox");
      Window.ratingbox = ratingbox;
      ratingbox.childNodes.forEach(node => {
        if (node.type == "radio" && node.value == rating) {
          node.checked = true;
        }
      });
    }
  },
  mounted() {
    if (this.ratingdata.previous != 0)
      this.fillRating(this.ratingdata.previous);
  }
};
</script>

<style scoped>
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

fieldset,
label {
  margin: 0;
  padding: 0;
}
body {
  margin: 20px;
}
h1 {
  font-size: 1.5em;
  margin: 10px;
}

/****** Style Star Rating Widget *****/

.rating {
  border: none;
  float: left;
}

.rating > input {
  display: none;
}
.rating > label:before {
  margin: 5px;
  font-size: 2em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

.rating > .half:before {
  content: "\f089";
  position: absolute;
}

.rating > label {
  color: #ddd;
  float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label {
  color: #ffd700;
} /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label {
  color: #ffed85;
}
</style>
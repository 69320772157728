<template>
  <div class="column is-2-desktop is-6-mobile">
    <div class="card" style="border-radius: 3px; padding-bottom: 10px">
      <div class="card-image">
        <figure class="image is-2by3">
          <router-link :to="{ name: type, params: {id: info.id } }">
            <img
              class="border-top"
              :src="image"
              alt="Poster image"
              style="border-top-left-radius: 3px; border-top-right-radius: 3px"
            />
          </router-link>
        </figure>
      </div>
      <div class="card-content" style="padding-bottom: 0.75rem;">
        <p class="title is-size-5 -desktop is-size-6-mobile">{{title}}</p>
      </div>
      <div class="action-buttons">
        <button
          title="Rate this item. Rating an item will remove it from your favorites. This can be changed in your TMDb account settings"
        >
          <font-awesome-icon icon="star" @click="openRating()"></font-awesome-icon>
        </button>
        <button
          title="Add/Remove this item from your watchlist"
          :class="{ 'is-selected': watchlist }"
          @click="handleWatchlist()"
        >
          <font-awesome-icon icon="plus"></font-awesome-icon>
        </button>
        <button
          title="Favorite/Unfavorite this item. This will update your viewing preferences to help recommend movies and TV shows that you might like"
          :class="{ 'is-selected': favorite }"
          @click="handleFavs()"
        >
          <font-awesome-icon icon="heart"></font-awesome-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TMDb from "@/utils/TMDb.js";

const tmdb = new TMDb();

export default {
  name: "favoritescard",
  props: ["info", "type"],
  data() {
    return {
      watchlist: false,
      favorite: false,
      rating: 0,
      notfound: require("@/assets/images/unknown.png"),
    };
  },
  computed: {
    image() {
      return this.info.poster_path != null
        ? "https://image.tmdb.org/t/p/w500/" + this.info.poster_path
        : this.notfound;
    },
    title() {
      return this.type == "tv" ? this.info.name : this.info.title;
    },
  },
  created() {
    this.type == "movies"
      ? tmdb.getMovieAccountStates(this.info.id).then((resp) => {
          this.favorite = resp.data.favorite;
          this.watchlist = resp.data.watchlist;
          resp.data.rated.value == undefined
            ? (this.rating = 0)
            : (this.rating = resp.data.rated.value);
          console.log(resp.data.rated.value);
        })
      : tmdb.getTvAccountStates(this.info.id).then((resp) => {
          this.favorite = resp.data.favorite;
          this.watchlist = resp.data.watchlist;
          resp.data.rated.value == undefined
            ? (this.rating = 0)
            : (this.rating = resp.data.rated.value);
        });
  },
  methods: {
    handleWatchlist() {
      if (this.watchlist == false) {
        this.watchlist = true;
        this.watchlistitem();
      } else {
        this.watchlist = false;
        this.watchlistitem();
      }
    },
    watchlistitem() {
      console.log("called");
      this.watchlist == true
        ? tmdb.processWatchlist(
            this.info.id,
            this.type == "movies" ? "movie" : "tv",
            true
          )
        : tmdb.processWatchlist(
            this.info.id,
            this.type == "movies" ? "movie" : "tv",
            false
          );
    },
    handleFavs() {
      if (this.favorite == false) {
        this.favorite = true;
        this.favoriteitem();
      } else {
        this.favorite = false;
        this.favoriteitem();
      }
    },
    favoriteitem() {
      this.favorite == true
        ? tmdb.processFavorites(
            this.info.id,
            this.type == "movies" ? "movie" : "tv",
            true
          )
        : tmdb.processFavorites(
            this.info.id,
            this.type == "movies" ? "movie" : "tv",
            false
          );
    },
    openRating() {
      this.$emit("rate", {
        id: this.info.id,
        type: this.type == "movies" ? "movie" : "tv",
        previous: this.rating / 2, //divided by two to convert from a 10 star to 5 star rating system
      });
    },
  },
};
</script>


<style scoped>
.action-buttons {
  display: flex;
  justify-content: center;
}
.action-buttons button {
  margin-right: 20px;
  height: 2rem;
  line-height: 20px;
  width: 2rem;
  font-size: 1em;
  font-weight: bold;
  border-radius: 50%;
  background-color: #05054a;
  color: white;
  text-align: center;
  cursor: pointer;
  border: none;
}

.action-buttons button:last-of-type {
  margin-right: 0px;
}

.action-buttons button:hover {
  opacity: 0.7;
  transition: opacity ease 200ms;
}

.action-buttons button:focus {
  outline: none;
}

.is-selected {
  background-color: dodgerblue !important;
}
</style>
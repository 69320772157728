<template>
  <div>
    <phlix-navbar></phlix-navbar>

    <div v-if="isSignedIn" class="container is-fluid">
      <h1 class="title">Recommendations</h1>
      <div class="tabs">
        <ul>
          <li :class="{ 'is-active': showFirstTab }" @click="showFirstTab = true">
            <a>Movies</a>
          </li>
          <li :class="{ 'is-active': !showFirstTab }" @click="showFirstTab = false">
            <a>TV shows</a>
          </li>
        </ul>
      </div>

      <div style="height: max-content" v-if="movie_loading == false" v-show="showFirstTab">
        <div class="columns is-multiline is-mobile">
          <recscard
            v-for="(movie, index) in movies"
            :info="movie"
            :key="index"
            type="movies"
            @deletemovie="deletemovie($event)"
            @rate="rate($event)"
          ></recscard>
        </div>
        <infinite-loading @infinite="infiniteMovieHandler" style="margin-bottom: 5rem" v-if="!moviefirstrun">
          <div slot="no-results">
            <p
              class="subtitle is-5"
              style="padding-top:3rem;padding-bottom: 3rem; margin-bottom:3rem"
            >No more items</p>
          </div>
          <div slot="no-more">
            <p
              class="subtitle is-5"
              style="padding-top:3rem; padding-bottom: 3rem; margin-bottom:3rem"
            >No more items</p>
          </div>
        </infinite-loading>
      </div>
      <div v-else>
        <p class="has-text-centered subtitle is-5" style="margin-top: 5rem">Loading...</p>
      </div>
      <div style="height: max-content" v-show="!showFirstTab">
        <div class="columns is-multiline is-mobile">
          <recscard
            v-for="(tvshow, index) in tv"
            :info="tvshow"
            :key="index"
            type="tv"
            @deletetv="deletetv($event)"
            @rate="rate($event)"
          ></recscard>
        </div>
        <infinite-loading @infinite="infiniteTvHandler" style="margin-bottom: 5rem" v-if="!tvfirstrun">
          <div slot="no-results">
            <p
              class="subtitle is-5"
              style="padding-top:3rem;padding-bottom: 3rem; margin-bottom:3rem"
            >No more items</p>
          </div>
          <div slot="no-more">
            <p
              class="subtitle is-5"
              style="padding-top:3rem;padding-bottom: 3rem; margin-bottom:3rem"
            >No more items</p>
          </div>
        </infinite-loading>
      </div>
    </div>

    <div
      v-else
      class="container is-fluid"
      style="display: flex; justify-content: center; margin: auto"
    >
      <p
        class="subtitle is-3"
        style="padding-top: 10rem"
      >Connect to TMDb to access your recommendations</p>
    </div>
    <rating
      v-if="showRating"
      :ratingdata="ratingdata"
      @closerating="showRating = false; ratingdata = {}"
    ></rating>
    <bottomnavbar item="recommendations"></bottomnavbar>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Cards from "@/components/Cards.vue";
import Card from "@/components/Card.vue";
import InfiniteLoading from "vue-infinite-loading";
import Recscard from "@/components/Recscard.vue";
import TMDb from "@/utils/TMDb.js";
import Rating from "@/components/Rating.vue";
import { Bus } from "@/utils/Bus.js";
import Bottomnavbar from "@/components/Bottomnavbar.vue";

const tmdb = new TMDb();
Window.tmdb = tmdb;

export default {
  name: "recommendations",
  data() {
    return {
      showFirstTab: true,
      showRating: false,
      ratingdata: {},
      favorites: [],
      movies: [],
      tv: [],
      moviepage: 1,
      movietotalpages: 1,
      moviefirstrun: true,
      tvfirstrun: true,
      tvpage: 1,
      tvtotalpages: 1,
      isSignedIn: false,
      movie_loading: true,
    };
  },
  methods: {
    fetchMovieRecommendationsPage(page = 1, from) {
      return new Promise((resolve, reject) => {
        tmdb
          .getMovieRecommendations(page)
          .then((resp) => {
            this.movie_loading = false;
            let m = this.movies.slice(0);
            resp.data.results.forEach((movie) => m.push(movie));
            this.movies = _.uniqBy(m, "id");
            this.moviepage = resp.data.page;
            this.movietotalpages = resp.data.total_pages;
            this.moviefirstrun = false;
            console.log("Fetched movie page " + page + " from " + from);
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      });
    },
    fetchTvRecommendationsPage(page = 1) {
      return new Promise((resolve, reject) => {
        tmdb
          .getTvRecommendations(page)
          .then((resp) => {
            let m = this.tv.slice(0);
            resp.data.results.forEach((tv) => m.push(tv));
            this.tv = _.uniqBy(m, "id");
            this.tvpage = resp.data.page;
            this.tvtotalpages = resp.data.total_pages;
            this.tvfirstrun = false;
            console.log("Fetched tv page " + page);
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      });
    },
    infiniteMovieHandler($state) {
      if (this.showFirstTab) {
        //movies
        if (
          this.moviepage >= this.movietotalpages &&
          this.moviefirstrun == false
        ) {
          console.log("Finished loading all movies");
          $state.complete();
        } else {
          this.fetchMovieRecommendationsPage(
            this.moviepage + 1,
            "infiniteMovieHandler"
          ).then(() => {
            console.log("Finished loading movie page " + this.moviepage);
            $state.loaded();
          });
        }
      }
    },
    infiniteTvHandler($state) {
      if (!this.showFirstTab) {
        //tv
        if (this.tvpage >= this.tvtotalpages && this.tvfirstrun == false) {
          console.log("Finished loading all tv shows");
          $state.complete();
        } else {
          this.fetchTvRecommendationsPage(this.tvpage + 1).then(() => {
            console.log("Finished loading tv page " + this.tvpage);
            $state.loaded();
          });
        }
      }
    },
    deletemovie(id) {
      this.movies = this.movies.filter((movie) => movie.id != id);
    },
    deletetv(id) {
      this.tv = this.tv.filter((tv) => tv.id != id);
    },
    rate(data) {
      this.ratingdata = data;
      this.showRating = true;
    },
  },
  created() {
    Bus.$on("signedoff", () => (this.isSignedIn = false));
    this.isSignedIn = tmdb.isSignedIn();
    if (this.isSignedIn) {
      this.fetchMovieRecommendationsPage(1, "created");
      this.fetchTvRecommendationsPage();
    }
  },
  components: {
    "phlix-navbar": Navbar,
    Card,
    Cards,
    InfiniteLoading,
    Recscard,
    Rating,
    Bottomnavbar,
  },
};
</script>

<style scoped>
</style>


